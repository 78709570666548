import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { FaPhoneVolume, FaLinkedin, FaEnvelope, FaTwitter } from "react-icons/fa"
import siteConfig from '../../data/siteConfig'
//import { withPrefix } from "gatsby"
import loadable from '@loadable/component'

import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'
import About from '../components/about'
import Skills from '../components/skills'
import Timeline from '../components/timeline'
//import Repositories from '../components/repositories'

import avisClients from "../video/avis-clients.mp4"
import Transcript from "file-loader!../video/transcript.vtt"
import Captions from "file-loader!../video/captions.vtt"
import Description from "file-loader!../video/description.vtt"

import TravelerVIP from "../video/TravelerVIP.mp4"
import { withPrefix } from 'gatsby'

const Layout = loadable(() => import('../components/layout'))

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.fontColor};
  opacity: .2;
`

const Home = ({ className, location }) => {
  // validate siteConfig settings
  //if (siteConfig.googleAnalyticsId === 'UA-000000000-1') {
 //   console.error('WARNING: Please set a proper googleAnalyticsId. See https://analytics.google.com for details.');
 // }

  const title = siteConfig.siteTitle
  const { keywords } = siteConfig
  return (
    <Layout location={location}>
      <SEO
        title={title}
        keywords={keywords}
      />

      <Hero
        heroImg={siteConfig.siteCover}
        title={title}
      />

      <Wrapper className={className} >
        <Container className="page-content" fluid>
          <Row>
            <Col xs={4} className='avatar'>
              <img
                className='avatar__image'
                src={(siteConfig.authorAvatar)}
                alt='Riad Joseph SEO'
              />
              <div className="social">
                {siteConfig.social.github && <a className="social-link github" href={siteConfig.social.github} target="_blank" rel="noreferrer">
                  <FaPhoneVolume className="social-icon" size="24" />
                </a>}
                {siteConfig.social.linkedin && <a className="social-link linkedin" href={siteConfig.social.linkedin} target="_blank" rel="noreferrer">
                  <FaLinkedin className="social-icon" size="24" />
                </a>}
                {siteConfig.social.twitter && <a className="social-link twitter" href={siteConfig.social.twitter} target="_blank" rel="noreferrer">
                  <FaTwitter className="social-icon" size="24" />
                </a>}
                {siteConfig.social.email && <a className="social-link email" href={`mailto:${siteConfig.social.email}`}>
                  <FaEnvelope className="social-icon" size="24" />
                </a>}
              </div>
            </Col>
          </Row>
         { /* <Separator /> */}
          <Row>
            <Col xs={5} sm={5}>
              <About title='Riad JOSEPH' text={siteConfig.authorDescription}/>
            </Col>
            <Col xs={3} sm={3}>
              <Skills title='Compétences techniques' skills={siteConfig.skills} />
            </Col>
          </Row>
          <Separator />
          <Timeline />
          <Separator />

        </Container>
        <Container>
        <Row>
            <Col xs={8} sm={8}>
            <h2>Avis Clients</h2>
            <video controls autoPlay style={{ width: `100%` }}>
                <source className='video' src={avisClients} type='video/mp4' />
                <track kind="transcript" srcLang="fr" src={Transcript} />
                <track kind="captions" srcLang="fr" src={Captions} />
                <track kind="description" srcLang="fr" src={Description} />
              </video>
            </Col>
            <Col xs={4} sm={4}>
            <small>100+ missions depuis 2018</small>
            </Col>
          </Row>
         { /* <Separator /> */}
        </Container>
        <Separator />
        <Container>
        <Row>
            <Col xs={8} sm={8}>
            <h3>Vu sur Dubai TV</h3>
            <video controls style={{ width: `100%` }}>
                <source className='video' src={TravelerVIP} type='video/mp4' />
                <track kind="transcript" srcLang="fr" src={Transcript} />
                <track kind="captions" srcLang="fr" src={Captions} />
                <track kind="description" srcLang="fr" src={Description} />
              </video>
            </Col>
            <Col xs={4} sm={4}>
            <small>TravelerVIP Luxury Holidays Startup</small>
            </Col>
          </Row>
        </Container>
        <Separator />
        <Container>
        <Row>
            <Col xs={8} sm={8}>
            <h3>Forbes 2016 Middle East Awards</h3>
            <img width="300" height="auto" src="/images/most-promising-startups.JPG" alt='Most promising startups Forbes 2016' />
            </Col>
            <Col xs={4} sm={4}>
            <small>HolidayMe Most Promising Startup</small>
            </Col>
          </Row>
        </Container>
        <Separator />
        <Container>
        <Row>
            <Col xs={4} sm={4}>
            <h4>Touche personnelle</h4>

            </Col>
            <Col xs={8} sm={8}>
           <small> <span role='img' aria-label='guitar'>🎸 </span>Ancien guitariste groupe metal</small>
            <small><span role='img' aria-label='feu de camp scout'>🏕 </span> Ancien scouts du Liban</small>
            <small><span role='img' aria-label='newspaper'>🗞 </span> Intérêt pour la géopolitique</small>
            <small> <span role='img' aria-label='camera'>📷 </span> Ancien photographe, j'aime la lecture (non-fiction) et les films d'auteur</small>
            <small> <span role='img' aria-label='camera'>🏡</span> À 50 min de Paris, du côté de Saint-Germain-en-Laye (78).</small>

            </Col>
            <span><img align="right" width="70px" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNzUycHQiIGhlaWdodD0iNzUycHQiIHZlcnNpb249IjEuMSIgdmlld0JveD0iMCAwIDc1MiA3NTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiA8cGF0aCBkPSJtNTg0LjM0IDQwNi42N2MtMC4wOTM3NSAyLjY5OTItMS4zMzIgNS4yMzA1LTMuNDA2MiA2Ljk1N2wtNzYuMDcgNjMuMDQ3LTc2LjA3IDYyLjg5OGgwLjAwMzkwNmMtMy41MTE3IDIuOTI5Ny04LjYyMTEgMi45Mjk3LTEyLjEzMyAwbC03Ni4wNy02Mi44OTgtNzUuOTIyLTYzLjA0N2MtMy4wMjM0LTIuNTM5MS00LjE1NjItNi42OTE0LTIuODM1OS0xMC40MTQgMS4zMTY0LTMuNzIyNyA0LjgwODYtNi4yMzgzIDguNzUzOS02LjMwODZoODEuMjVjOC40Mzc1LTMzLjg4MyA5LjgyODEtNTkuOTIyIDUuNzczNC03OC41ODYtNC4zMzItMTkuOTM4LTEzLjgyLTMxLjY4NC0yOC41NjItMzkuMDctMjkuNDg0LTE0Ljc3My04My42OC04LjY3MTktMTQ4Ljg4IDEzLjc2MnYwLjAwMzkwNmMtNC4zNzExIDEuNDgwNS05LjE3NTgtMC40MDYyNS0xMS4zNzEtNC40Njg4cy0xLjE0NDUtOS4xMTMzIDIuNDkyMi0xMS45NjFjODMuODE2LTY0LjIzNCAxNjguNDQtODAuMjM4IDIzMS45MS01NC45MDYgNTkuNSAyMy43NSA5OC4zMDkgODQuMDc4IDk4LjcxMSAxNjguMTIgMCAyLjM1OTQtMC4wOTc2NTYgNC43MDctMC4xNDg0NCA3LjEwNTVoNzMuMTA5YzIuNTY2NCAwIDUuMDE5NSAxLjAzNTIgNi44MDQ3IDIuODc4OSAxLjc4NTIgMS44Mzk4IDIuNzUgNC4zMjQyIDIuNjY4IDYuODg2N3oiIGZpbGwtcnVsZT0iZXZlbm9kZCIvPgo8L3N2Zz4K" alt="Left Icon 992836" class="styles__Image-sc-2erpfv-1 gbnltd"></img></span>
          </Row>
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }

  .avatar__image {
    box-shadow: 5px 6px 7px 0px rgb(0 0 0 / 75%);
    max-width: 200px;
    border-radius: 15px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077B5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }

  button {
    background-color: #636363;
    color: #ffffff;
    border: 0;
    padding: 10px;
  }

  button:hover {
    background-color: #000000;
    color: #ffffff;
    border: 0;
    padding: 10px;
  }

  small {
    color: grey;
  }
  `
